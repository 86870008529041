import React from 'react';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    display: flex;
    font-family: Verdana;
    flex-direction: column;
    margin: 12px 0px 0px 0px;
    background-color: #222;
    color: #fff;
    padding: 12px 0px 12px 0px;
  

    left: 0;
    bottom: 0;
    width: 100%;
`;

export const LogoRadar = styled.img`
    width: 60%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
`; 

export const UpperDiv = styled.div`
    font-size:14px;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
`;

export const LowerDiv = styled.div`
    font-size:12px;
    text-align: center;
    margin: -10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    
`;