import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

import {
  Wrapper,
  LogoRadar,
  PatrocinioBanner,
  Container,
  StyledTitle,
  Separator,
} from "./styles"

import { Brace, Header, PatrocinioLogoRadar } from '../HomeMobile/styles';

import RoutedToggleMenu from '../RoutedToggleMenu/RoutedToggleMenu';
import PostList from '../PostList'
import SelectCategory from '../SelectCategory/SelectCategory';
import Footer from '../Footer/Footer';
import { linhas, categoriesStyles, siteAddress, logoAddress, patrocinioBanner } from '../constants';
import Description from '../DescriptionMobile/Description';
import AgendaSelector from '../AgendaSelector/AgendaSelector';

function PaginaCategoria({categoria}) {
  // const [subCategory, setSubCategory] = useState('categoria');
  const [subCategory, setSubCategory] = useState(() => {
    // Initialize from localStorage if available, or use a default value
    return localStorage.getItem('selectedCategory') || categoria;
  });

  const [agendaDate, setAgendaDate] = useState(getDateRanges());
  // console.log(`Categoria: ${categoria}`)
  const filteredSlug = categoria.replace(/-/g,'_')
  let filterLists = [categoriesStyles[filteredSlug].tags]
  let filterListsFeirasEventos = [categoriesStyles['feiras_e_eventos'].tags]
  
  localStorage.setItem("Categoria", categoria)
  let oldCategoria = localStorage.getItem('OldCategoria') || ''
  // console.log("Categoria atual", categoria)
  // console.log("Old Categoria", oldCategoria)
  if (categoria != oldCategoria) {
    // console.log("Reload Page")
    localStorage.setItem('OldCategoria', categoria)
    localStorage.setItem('selectorGetThisS', '')
    localStorage.setItem('eventIndex', '')
    setSubCategory('')
    window.location.reload(true);
    console.log("Reloaded Page")
  }


  // console.log("sub category:",  subCategory)
  // console.log("local storage:", localStorage.getItem('selectedCategory'))

  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
      console.log("PAGINA")
      let storedValue = localStorage.getItem('selectedCategory')
      let counter = 0

      // Apply the function to each item in the array
      let filterListsClean = filterLists[0].map((item => {
        // console.log('Mapping:', item);
        return removeAccentsAndSpaces(item);
      }));
      // console.log("Clean filter", filterListsClean)
      const isValueInArray = filterListsClean.includes(storedValue);
      
      if (isValueInArray && storedValue != "") {
        let index = filterListsClean.indexOf(storedValue)
        // console.log("Filter list index", filterLists[0][index])
        let categoryName = filterLists[0][index]
        // console.log("Index", index)
        // console.log("categoryName", categoryName)

      if (localStorage.getItem('Counter')) {
        counter = +localStorage.getItem('Counter')
      }
      if (counter < 2) {
        counter += 1
        localStorage.setItem('Counter', counter)
      }
      console.log('Counter', counter)
      console.log('Stored', storedValue)
      if (storedValue != "" && counter <= 2) {
        console.log("Using stored - ", storedValue )
        console.log("Using filtered - ", categoryName )
        setSubCategory(storedValue); // Reset state1   
      } else {
        console.log("Removing stored category")
        localStorage.setItem('selectedCategory', '') 
        setSubCategory(''); // Reset state1   
        counter = 0
        localStorage.setItem('Counter', counter)
      }
    } else {
      console.log("Removing stored for clear start")
      localStorage.setItem('selectedCategory', '') 
      setSubCategory(''); // Reset state1   
      counter = 0
      localStorage.setItem('Counter', counter)
    }

  }, []);

  const handleSelector = (newSubCategory) => {
    newSubCategory = newSubCategory.replace(/\s+/g, '-')
    newSubCategory = newSubCategory.replace(/\//g, '-', '-')
    newSubCategory = newSubCategory.replace('é','e')
    newSubCategory = newSubCategory.replace('ó','o')
    newSubCategory = newSubCategory.replace('ç','c')

    setSubCategory(newSubCategory)
    localStorage.setItem('selectedCategory', newSubCategory);
    console.log("local storage:", localStorage.getItem('selectedCategory'))
    
    console.log("New subCategory", newSubCategory)
    window.location.reload(true);
  }
  
  const handleAgendaSelector = (newDate, selectedDateRange) => {
    console.log("New Date Handler:", newDate)
    console.log("Start Date:", selectedDateRange.startDate.toISOString().split('T')[0])
    console.log("New Date Handler:", selectedDateRange.endDate.toISOString().split('T')[0])
    setAgendaDate(selectedDateRange)

    setSubCategory(newDate)
    localStorage.setItem('selectedCategory', newDate);
    localStorage.setItem('selectedDateRange', selectedDateRange);
    console.log("local storage:", localStorage.getItem('selectedCategory'))
    console.log("local selectedDateRange:", localStorage.getItem('selectedDateRange'))
    console.log("New selectedDateRange", selectedDateRange)
    console.log("New subCategory", newDate)
    window.location.reload(true);


  }


  return (
    <Wrapper>
      <Header>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")} />
        <RoutedToggleMenu />      
      </Header>
      <Brace>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")} />
        <RoutedToggleMenu />
      </Brace>
      <Container>
        <StyledTitle>{categoria.replace(/-/g,' ')}</StyledTitle>
      </Container>
      <Separator />
      { categoria != 'agenda' && categoria != 'radar-secreto' ?
      <SelectCategory categoria={subCategory} subCategoria={subCategory} filterLists={filterLists} callBack={handleSelector}/> 
      : '' } 
      { categoria == 'agenda' ? <AgendaSelector callback={handleAgendaSelector}/> : ''} 
      <Description categoria={categoria}/>
      {/* {categoria == 'agenda' ? <DateRangeComponent/> : ''} */}
      {/* <Patrocinio>Patrocinio</Patrocinio> */}
      <PatrocinioLogoRadar src={patrocinioBanner} />
      <PostList categoria={categoria} subCategoria={subCategory} agendaDate={agendaDate}/>
      {/* <MenuCanais /> */}
      <Footer/>
    </Wrapper>
  );
};

export default PaginaCategoria;

function getDateRanges() {
  const today = new Date();
  const currentDay = today.getDay();
  const startDate = new Date(today);
  const endDate = new Date(today);
  const next365DaysEndDate = new Date(today); 

  // Calculate the start date (last Monday)
  const start = ((currentDay - 1) < 0) ? 6 : (currentDay - 1);
  // console.log('START', currentDay)
  // console.log('START', today)
  startDate.setDate(today.getDate() - start);
  // console.log('START', startDate)

  // Calculate the end date (next Sunday)
  const end = (currentDay === 0) ? 0 : (7 - currentDay);
  endDate.setDate(today.getDate() + end);
  next365DaysEndDate.setDate(today.getDate() + 365);

  return (
    {
      label: 'toda agenda',
      startDate: startDate,
      endDate: next365DaysEndDate,
    }
    )
}


// function removeAccentsAndSpaces(inputString) {
//   // Remove accents using Unicode normalization
//   const normalizedString = inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

//   // Replace white spaces with hyphens
//   const stringWithHyphens = normalizedString.replace(/\s+/g, '-');

//   return stringWithHyphens;
// }
const unorm = require('unorm');
function removeAccentsAndSpaces(inputString) {
  console.log("NEW FUNCTION")
  // Check if charCodeAt is available on the input string
  if (typeof inputString !== 'string' || typeof inputString.charCodeAt !== 'function') {
    // console.log("Entrada:", inputString)
    return inputString;
  }

  const normalizedString = unorm.nfd(inputString).replace(/[\u0300-\u036f]/g, '');
  // console.log("Normalize", normalizedString)
  return normalizedString.replace(/\s+/g, '-');
}