import React, { useEffect, useState } from 'react';
import { DropdownContainer} from './styles';
import { Wrapper } from '../HomeMobile/styles';
import { siteAddress} from '../constants';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

const SelectCategory = ({categoria, subCategoria, filterLists, callBack}) => {
  const [selectedCategories, setSelectedCategories] = useState([subCategoria]);
  const [categoryIds, setCategoryIds] = useState([]);

  console.log("Selector - categoria", categoria)
  console.log("Selector - subcategoria", subCategoria)

  // let filterName = [localStorage.getItem('SelectedCategory')] || ['categoria']
  let filterName = subCategoria
  categoria == 'feiras-e-eventos' ? filterName = ['mês'] : filterName = ['categoria']
  categoria == 'radar-da-linha' ? filterName = ['linha'] : filterName = filterName
  categoria == 'radar-da-estacao' ? filterName = ['estação'] : filterName = filterName

  // console.log(`Filter Lists: ${filterLists}`)

  // Handle the goBack button
  const navigate = useNavigate();

  const goBack = () => {
    callBack('')
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    console.log("setting selected categories")
    let storedValue = localStorage.getItem('SelectedCategory')
    let eventTargetValue = localStorage.getItem('selectorGetThisS')
    let eventIndex = localStorage.getItem('eventIndex')
    if (subCategoria != "") {
      // console.log("Using stored selector" - storedValue)
      // console.log("Selector - Category", categoria)
      // console.log("Selector - Update Category", subCategoria)
      // const updatedCategories = [subCategoria];
      // setSelectedCategories(updatedCategories)
      console.log("Selected Before", selectedCategories)
      const updatedCategories = [categoria];
      console.log("Update Category", updatedCategories)
      updatedCategories[eventIndex] = eventTargetValue;
      console.log("Update Category", updatedCategories[eventIndex])
      setSelectedCategories(updatedCategories);
      // window.location.reload(true);
      console.log("Selected After", selectedCategories)
    

    } else {
      console.log("Selector - Removing stored selector", storedValue)
      setSelectedCategories([])
    }
  }, []);
  
  const handleCategoryChange = (event, index) => {
    console.log("event:", event.target.value)
    console.log("eventIndex:", index)
    localStorage.setItem("selectorGetThisS", event.target.value)
    localStorage.setItem("eventIndex", index)
    callBack(event.target.value)
    console.log("Selected Before", selectedCategories)
    const updatedCategories = [categoria];
    console.log("Update Category", updatedCategories)
    updatedCategories[index] = event.target.value;
    console.log("Update Category", updatedCategories[index])
    setSelectedCategories(updatedCategories);
    // window.location.reload(true);
    console.log("Selected After", selectedCategories)
  };


  return (
    <div>

      {filterLists.map((categoryList, index) => (
        <DropdownContainer key={index}>
          <select
            value={selectedCategories[index] || ''}
            onChange={(e) => handleCategoryChange(e, index)}
            >
            <option value="">{filterName[index]}</option>
            {categoryList.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </DropdownContainer> 
      ))}

    </div>
  );
};

export default SelectCategory;
