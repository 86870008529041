import React from 'react';
import {StyledColum, StyledImage, WrapperApoio, StyledTitle, Separator } from './styles'
import { siteAddress, apoieAddress } from '../constants';

const ApoioRadar = () => {
 

  return (
      <WrapperApoio>
        <StyledTitle>Apoio</StyledTitle> 
        <Separator /> 
        <StyledColum>
            <StyledImage src={apoieAddress} alt="Image 1"/>
        </StyledColum>
      </WrapperApoio>
  );
};

export default ApoioRadar;