import React from 'react';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 12px 0px 0px 0px;
    padding: 12px 0px 12px 0px;
    
    font-family: Verdana;
    color: #fff;
    background-color: #222;
    
    left: 0;
    bottom: 0;
    width: 100%;
`;

export const LogoRadar = styled.img`
    width: 18%;
    margin: 0px 0px 0px 250px;
    margin: 10px; /* Adjust spacing between lines */
    text-align: center;

`;


export const CenteredTwoColumnFlex = styled.div`
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
`;

export const TwoColumnFlex = styled.div`
display: flex;
align-items: center; /* Center items vertically */
`;

export const Column = styled.div`
  flex: 1; /* Each column takes equal space */  
  align-items: center; /* Center items vertically */
`;

export const UpperDiv = styled.div`
    margin-left: 12px;
    text-align: center;
`;

export const LowerDiv = styled.div`
    font-size:12px;
    text-align: center;
`;