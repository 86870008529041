import React from 'react';
import styled from 'styled-components';

export const StyledDateSquare = styled.div`
width: 60px;
height: 60px;
background-color: ${props => props.backgroundcolor};
text-align: center;
color: #fff;
font-family: Arial, sans-serif;
font-size: 18px;
line-height: 1.2;
border-radius: 5px;
margin: 0px 0px 0px 15px;

/* Add Flexbox properties for vertical centering */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const DataEventoAgendaFormat = styled.div`
    font-size:20px;
    font-weight: bold;
    color: #dd0000;
    margin: 12px 0px 0px 12px;

`;