import React from 'react';
import styled from 'styled-components';




// Define the styled component
export const StyledButton = styled.button`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Header = styled.header`
  display: flex;
  position: fixed;
  align-items: center;
  // background-color: #d42326;
  background-color: #7800d0;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 0px 10px 0px;
  border: none;
  cursor: pointer;
  z-index: 100;
`;

export const Brace = styled.div`
display: flex;
align-items: center;
background-color: #ffffff00;
color: #ffffff;
font-size: 16px;
padding: 10px 0px 10px 0px;
border: none;
cursor: pointer;

`

export const LogoRadar = styled.img`
  width: 70%;
`;



// Define the styled component
export const Patrocinio = styled.div`
display: flex; 
align-items: center;
color: #fff;
background-color: #aa00aa;
// margin: 10px 0px 10px 0px;
padding: 0px;
width: 100%;
height: 70px;
`;

export const PatrocinioText = styled.p`
font-size: 16px;
padding: 0px 0px 0px 10px;
margin-left: 50px;
flex:1;
`;

export const PatrocinioLogoRadar = styled.img`
width: 90%;
height: auto;
margin-left: 5%;
margin-right: 5%;
margin-top: 10px;
margin-bottom: 10px;

`;

// Define the styled component
export const FooterWrapper = styled.footer`
display: flex;
flex-direction: column;

margin: 12px 0px 12px 0px;
background-color: #222;
color: #fff;
padding: 12px 0px 12px 0px;
text-align: center;

left: 0;
bottom: 0;
width: 100%;
`;

export const Wrapper = styled.div`
  background-color: 'black';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;
