import React, { useEffect, useState } from 'react';

import {Wrapper, StyledImage, PostTitle, PostContentPreview, StyledLink, PostImage, LargeArrowIcon, Container} from './styles'
import { categoriesStyles, siteAddress } from '../constants';

import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

const ChamadaMobile = (props) => {
  const [latestPost, setLatestPost] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const categorySlug = props.categoria;

  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    if (path != '/metro'){
      navigate(path);
    }
  };


  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        // Replace <category_slug> with the slug of the desired category

        // Get the category ID using the slug
        const categoryResponse = await fetch(`https://${siteAddress}/wp-json/wp/v2/categories?slug=${categorySlug}`);
        const categoryData = await categoryResponse.json();
        const categoryId = categoryData[0]?.id;
        console.log(categoryId)

        // Fetch the most recent post in the category
        const postResponse = await fetch(`https://${siteAddress}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=1&_embed`);
        const postData = await postResponse.json();
        setLatestPost(postData[0]);

        if (postData[0].featured_media) {
          const mediaResponse = await fetch(`https://${siteAddress}/wp-json/wp/v2/media/${postData[0].featured_media}`);
          const mediaData = await mediaResponse.json();
          setImageUrl(mediaData.source_url);
        }


      } catch (error) {
        console.error(error);
      }
    };

    fetchLatestPost();
  }, []);
  // let imgUrl = process.env.PUBLIC_URL + '/assets/pirate.jpg';
  // let imgUrl = `${process.env.PUBLIC_URL}/pirate.jpg`;
  const filteredSlug = categorySlug.replace(/-/g,'_')
  console.log(filteredSlug)
  const nestedDictionary = categoriesStyles[filteredSlug];
  console.log(nestedDictionary)

  return (
    <Wrapper backgroundcolor={nestedDictionary.backgroundcolor} onClick={() => handleNavigation("/"+props.categoria.replace("chamada-", ''))}> 
    {latestPost ? (
        <div>
          <PostImage>
            {imageUrl && <StyledImage src={imageUrl} alt="Post Image" />}
            <PostTitle backgroundcolor={nestedDictionary.backgroundcolorTitle}>{latestPost.title.rendered}</PostTitle>
          </PostImage>
          <PostContentPreview dangerouslySetInnerHTML={{ __html: latestPost.content.rendered}} />
          {/* <Container>
          {props.categoria == 'chamada-metro' ? '' : <LargeArrowIcon/>}
          </Container> */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Wrapper>
  );
};

export default ChamadaMobile;