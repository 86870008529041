import React from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Header,
  LogoRadar,
  Patrocinio,
  PatrocinioText,
  PatrocinioLogoRadar,
  Brace,
  Column,
  ColumnsContainer,
  Separator,
} from "./styles"

import RoutedToggleMenu from '../RoutedToggleMenu/RoutedToggleMenu';
import Capa from '../Capa';
import ChamadaMobile from '../ChamadaMobile/ChamadaMobile'
import MenuCanais from '../MenuCanais/MenuCanais';
import Footer from '../Footer/Footer';
import { siteAddress } from '../constants';
import Destaques from '../Destaques/Destaques';
import Apoio from '../Apoio/apoio';
import ApoioRadar from '../ApoioRadar/apoioRadar';
import { patrocinioBanner } from '../../Mobile/constants';
// import { Separator } from '../Destaques/styles';
// import { Patrocinio, PatrocinioLogoRadar } from './styles';
let logoAddress = 'https://' +siteAddress+ '/wp-content/uploads/2023/09/Logo-radar-branco-fundo-Transparente.png'
let patrocinioImg = 'https://' +siteAddress+ '/wp-content/uploads/2023/09/Icone-patrocinio-2.webp'

function Home() {

  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const ThreeColumns = ({ content1, content2, content3 }) => {
    return (
      <ColumnsContainer>
        <Column>{content1}</Column>
        <Column>{content2}</Column>
        <Column>{content3}</Column>
      </ColumnsContainer>
    );
  };


  return (
    <Wrapper>
      <Header>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")}/>
        <RoutedToggleMenu />
      </Header>


      <Brace>
        <LogoRadar src={logoAddress}/>
        <RoutedToggleMenu />
      </Brace>


      <ThreeColumns
        content1={<Capa />}
        content2={<MenuCanais />}
        content3={<PatrocinioLogoRadar src={patrocinioBanner} />}
      />
      
      
      <Destaques categoria='destaques-da-agenda'/>
      <Separator />
      <ThreeColumns
        content1={<ChamadaMobile categoria='chamada-arte-e-cultura'/>}
        content2={<ChamadaMobile categoria='chamada-agenda'/>}
        content3={<ChamadaMobile categoria='chamada-feiras-e-eventos'/>}
      />
      
      <ThreeColumns
         content1={<ChamadaMobile categoria='chamada-comer-e-beber'/>}
         content2={<ChamadaMobile categoria='chamada-radar-secreto'/>}
         content3={<ChamadaMobile categoria='chamada-metro'/>}
      />
      
      {/* <Apoio /> */}
      <ApoioRadar/>      
      <Footer/>

      
    </Wrapper>
  );
};

export default Home;