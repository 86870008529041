import React from 'react';
import styled from 'styled-components';



export const WrapperApoio = styled.div`
  margin-bottom: 25px;
  margin-left: 7.5%;
  margin-right: 9.5%;
`;

export const StyledTitle = styled.h1`
  font-size: 24px;
  text-transform: uppercase;
  margin: 0px 0px 0px 5px;
  padding: 0px 0px 0px 0px;
`;
  
export const Separator = styled.hr`
  border: none;
  border-top: 3px solid #333;
  margin: 0px 5px 0px 5px;
`;

export const StyledColum = styled.div`
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
  margin: 10px 5px 10px 5px;
`;

export const StyledImage = styled.img`
  width: 50%; /* Adjust as needed */
`;
