import React from 'react';
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align:center;
  background-color:${props => props.backgroundcolor};
  color:white;
  margin: 12px 0px 12px 0px;
`;

export const PostImage = styled.div`
  position: relative;
  display: inline-block;
  // width: 100%;
`;


export const PostTitle = styled.div`
position: absolute;
font-size: 22px;
text-transform: uppercase;
top: 0;
left: 0;
width: 100%;
background-color: ${props => props.backgroundcolor};
color: #fff;
padding: 10px;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const PostContentPreview = styled.div`
  font-size: 22px;
  background-color:${props => props.backgroundcolor};
  margin-left: 9%;
  margin-right: 9%;
  margin-top: 8px;
`;

export const StyledImage = styled.img`
max-width: 100%;
// width:10%;
max-height: 100%;
`;

export const StyledLink = styled.a`
  color: gray;
  text-decoration: underline;
  cursor: pointer;
  padding: 12px;
  margin-botton: 100px;
  margin-top: 100px;
  
  &:hover {
    opacity: 0.6;
  }
`;


export const ArrowIcon = styled(FaArrowRight)`
margin-bottom:0px;
margin-left:220px;
color: #000;

padding-left: 100px;
/* Add any additional styles for the icon here */
`;

export const Container = styled.div`
display: grid;
grid-template-columns: 1fr auto;
align-items: center;

`;


export const LargeArrowIcon = styled(ArrowIcon)`
font-size: 20px;
margin-bottom:15px;
  /* Add any additional styles for the icon here */
`;
