import React from 'react';
import styled from 'styled-components';

// Define the styled component
export const StyledButton = styled.button`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: #d42326;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  border: none;
  cursor: pointer;
`;

export const LogoRadar = styled.img`
  margin-right: 20px;
  width: 50%;
`;

// Define the styled component
export const Footer = styled.footer`
display: flex;
flex-direction: column;
background-color: #222;
color: #fff;
padding: 12px 0px 12px 0px;
text-align: center;
position: fixed;
left: 0;
bottom: 0;
width: 100%;
`;

export const Wrapper = styled.div`
  background-color: 'black';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

