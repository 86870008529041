import React from 'react';
import "./styles.css"
import { DataEventoAgendaFormat } from './styles';

const PostDate = (props) => {

  try{
    const [date, year, month, day] = parseDate(props.date, props.original)
    const [dateE, yearE, monthE, dayE] = parseDate(props.dateE, props.original)
    const [weekdayStart, monthNameStart] = GetWeekDayAndMonth(date)
    const [weekdayEnd, monthNameEnd] = GetWeekDayAndMonth(dateE)

    return (
        <DataEventoAgendaFormat>
          {day} {monthNameStart} {'>'} {dayE} {monthNameEnd}
        </DataEventoAgendaFormat>
    );
  } catch(error) {
    console.log(error)
  }
  return (
<div></div>
  );
}

export default PostDate;

function parseDate(dateString, original=false) {

  try{
     const parts = dateString.split('-');
  
    if (parts.length === 3 && original) {
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January)
      const year = parseInt(parts[0], 10);
  
      // Create a new Date object
      const date = new Date(year, month, day);
      // console.log("DATE:", date)
      return [date, year, month, day];
    } else if (parts.length === 3 ) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January)
      const year = parseInt(parts[2], 10);
  
      // Create a new Date object
      const date = new Date(year, month, day);
      // console.log("DATE:", date)
      return [date, year, month, day];
    }
  } catch (error) {
    console.error(error);
  }
  
    return null; // Invalid date format
  }


 function GetWeekDayAndMonth (dateString)  {
    // const dateString = props.day+"-"+props.month+"-"+props.year
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const day = date.getDate();
    const monthOfYear = date.getMonth();
    const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const months = ['JAN','FEV','MAR','ABR','MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
    const weekday = weekdays[dayOfWeek]
    const month = months[monthOfYear]

    return [weekday, month]
  }