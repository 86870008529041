import React, { useEffect, useState } from 'react';
import { DropdownContainer, SelectorWrapper, StyledOption} from './styles';
import { Wrapper } from '../Home/styles';
import { siteAddress} from '../constants';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

const SelectCategory = ({categoria, filterLists, callBack}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [posts, setPosts] = useState([]);

  let filterName = ['categoria']
  categoria == 'feiras-e-eventos' ? filterName = ['mês'] : filterName = ['categoria']
  categoria == 'radar-da-linha' ? filterName = ['linha'] : filterName = filterName
  categoria == 'radar-da-estacao' ? filterName = ['estação'] : filterName = filterName

  // console.log(`Filter Lists: ${filterLists}`)

  // Handle the goBack button
  const navigate = useNavigate();

  const goBack = () => {
    callBack('')
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    setSelectedCategories([])
  }, []);

  useEffect(() => {
    const fetchCategoryIds = () => {
      const ids = selectedCategories.map((selectedCategory, index) => {
        const categoryList = filterLists[index];
        console.log("Category List",categoryList)
        console.log("Category fetch selectedCategory", selectedCategory)
        // const foundCategory = categoryList.find((category) => category.slug === selectedCategory);
        // console.log(categoryList.find((category) => category === selectedCategory));
        // console.log("found Category", foundCategory)
        callBack(selectedCategory)
        // Mudar a forma de recuperar ID para a URL da API e devolver para o filtro do PostList
        // return foundCategory ? foundCategory.id : null;
      });
      setCategoryIds(ids.filter((id) => id !== null));
      console.log(`Category IDs: ${categoryIds}`)
    };

    fetchCategoryIds();
  }, [selectedCategories, filterLists]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (categoryIds.length > 0) {
          const response = await fetch(
            `http://${siteAddress}/wp-json/wp/v2/posts?categories=${categoryIds.join(',')}`
          );
          const data = await response.json();
          setPosts(data);
        } else {
          setPosts([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPosts();
  }, [categoryIds]);

  const handleCategoryChange = (event, index) => {
    console.log("event:", event.target.value)
    callBack(event.target.value)
    console.log("Selected Before", selectedCategories)
    const updatedCategories = [categoria];
    console.log("Update Category", updatedCategories)
    updatedCategories[index] = event.target.value;
    console.log("Update Category", updatedCategories[index])
    setSelectedCategories(updatedCategories);
    console.log("Selected After", selectedCategories)
  };

  return (
    <SelectorWrapper>

      {filterLists.map((categoryList, index) => (
        <DropdownContainer key={index}>
          <select
            value={selectedCategories[index] || ''}
            onChange={(e) => handleCategoryChange(e, index)}
            >
            <StyledOption value="">{filterName[index]}</StyledOption>
            {categoryList.map((category) => (
              <StyledOption key={category} value={category}>
                {category}
              </StyledOption>
            ))}
          </select>
        </DropdownContainer> 
      ))}

    </SelectorWrapper>
  );
};

export default SelectCategory;
