import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Desktop/Home/Home';
import HomeMobile from './components/Mobile/HomeMobile/HomeMobile';
import About from './components/Mobile/About';
import PaginaCategoria from './components/Mobile/PaginaCategoria/PaginaCategoria';
import PaginaCategoriaDesktop from './components/Desktop/PaginaCategoriaDektop/PaginaCategoriaDesktop';


function App() {
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
    navigator.userAgent
  );
  console.log(isMobile)
  return (  
      <Router>
        <Routes>
           <Route path="/" element={isMobile ? <HomeMobile/> : <Home/>} />        
           <Route path="/about" element={<About/>} />
           <Route path="/chamada-arte-e-cultura" element={isMobile 
           ? <PaginaCategoria categoria='chamada-arte-e-cultura'/>
           : <PaginaCategoriaDesktop  categoria='chamada-arte-e-cultura'/>} />
           <Route path="/chamada-lugares-para-visitar" element={isMobile 
            ? <PaginaCategoria categoria='chamada-lugares-para-visitar'/>
            : <PaginaCategoriaDesktop categoria='chamada-lugares-para-visitar'/>} />
           <Route path="/chamada-comer-e-beber" element={isMobile 
            ? <PaginaCategoria categoria='chamada-comer-e-beber'/>
            : <PaginaCategoriaDesktop categoria='chamada-comer-e-beber'/>} />
           <Route path="/chamada-feiras-e-eventos" element={isMobile 
            ? <PaginaCategoria categoria='chamada-feiras-e-eventos'/>
            : <PaginaCategoriaDesktop categoria='chamada-feiras-e-eventos'/>} />
           <Route path="/chamada-radar-secreto" element={isMobile 
            ? <PaginaCategoria categoria='chamada-radar-secreto'/>
            : <PaginaCategoriaDesktop categoria='chamada-radar-secreto'/>} />
           <Route path="/chamada-agenda" element={isMobile 
            ? <PaginaCategoria categoria='chamada-agenda'/>
            : <PaginaCategoriaDesktop categoria='chamada-agenda'/>} />
           <Route path="/lugares-para-visitar" element={isMobile 
            ? <PaginaCategoria categoria='lugares-para-visitar'/>
            : <PaginaCategoriaDesktop categoria='lugares-para-visitar'/>} />
           <Route path="/arte-e-cultura" element={isMobile 
            ? <PaginaCategoria categoria='arte-e-cultura'/>
            : <PaginaCategoriaDesktop categoria='arte-e-cultura'/>} />
           <Route path="/agenda" element={isMobile 
            ? <PaginaCategoria categoria='agenda'/>
            : <PaginaCategoriaDesktop categoria='agenda'/>} />
           <Route path="/compras" element={isMobile 
            ? <PaginaCategoria categoria='compras'/>
            : <PaginaCategoriaDesktop categoria='compras'/>} />
           <Route path="/comer-e-beber" element={isMobile 
            ? <PaginaCategoria categoria='comer-e-beber'/>
            : <PaginaCategoriaDesktop categoria='comer-e-beber'/>} />
           <Route path="/radar-secreto" element={isMobile 
            ? <PaginaCategoria categoria='radar-secreto'/>
            : <PaginaCategoriaDesktop categoria='radar-secreto'/>} />
           <Route path="/feiras-e-eventos" element={isMobile 
            ? <PaginaCategoria categoria='feiras-e-eventos'/>
            : <PaginaCategoriaDesktop categoria='feiras-e-eventos'/>} />
           <Route path="/roteiros" element={isMobile 
            ? <PaginaCategoria categoria='roteiros'/>
            : <PaginaCategoriaDesktop categoria='roteiros'/>} />
           <Route path="/radar-da-linha" element={isMobile 
            ? <PaginaCategoria categoria='radar-da-linha'/>
            : <PaginaCategoriaDesktop categoria='radar-da-linha'/>} />
           <Route path="/radar-da-estacao" element={isMobile 
            ? <PaginaCategoria categoria='radar-da-estacao'/>
            : <PaginaCategoriaDesktop categoria='radar-da-estacao'/>} />
        </Routes>
      </Router>
  );
}

export default App;
