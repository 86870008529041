import React from 'react';
import styled from 'styled-components';

export const MenuCanaisWrapper = styled.footer`
  background-image: url('https://spguide.com.br/wp-content/uploads/2023/08/Home-Img-Menu.webp');
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; /* Prevent the image from repeating */
`;

export const LogoRadar = styled.img`
    width: 70%;
`;

export const Container = styled.div`
  color:white;
  font-weight: ${props => props.fw};
  // text-transform: lowercase;
  font-size: 25px;
  background-color: ${props => props.backgroundcolor};
  // opacity: ${props => props.opacity};
  margin-bottom: ${props => props.mb};
  margin-top: ${props => props.mt};
  padding: 7px 0px 7px 15px;
  display: flex;
 
`; 
