import React from 'react';
import DateSquare from './DateSquare';
import {DateSquareContainer} from './styles.js';
// import {logoAddress }from "../constants.js"
import "./styles.css"

const EventoDate = (props) => {

  try{
    const [date, year, month, day] = parseDate(props.date, props.original)
    const [dateE, yearE, monthE, dayE] = parseDate(props.dateE, props.original)


    return (
        // <div className="date-squares-container-desktop">
          <DateSquareContainer>
            <DateSquare backgroundcolor='#3498db' suff="de" dateString={date} />
            {props.label !== 'toda agenda' ? <DateSquare backgroundcolor='#f4672c' suff="a" dateString={dateE} /> : null}
          </DateSquareContainer>
        // </div>
    );
  } catch(error) {
    console.log(error)
  }
  return (
<div></div>
  );
}

export default EventoDate;

function parseDate(dateString, original=false) {

  try{
     const parts = dateString.split('-');
  
    if (parts.length === 3 && original) {
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January)
      const year = parseInt(parts[0], 10);
  
      // Create a new Date object
      const date = new Date(year, month, day);
      // console.log("DATE:", date)
      return [date, year, month, day];
    } else if (parts.length === 3 ) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January)
      const year = parseInt(parts[2], 10);
  
      // Create a new Date object
      const date = new Date(year, month, day);
      // console.log("DATE:", date)
      return [date, year, month, day];
    }
  } catch (error) {
    console.error(error);
  }
  
    return null; // Invalid date format
  }