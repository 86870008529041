import React from 'react';
import styled from 'styled-components';


// Define the styled component
export const Header = styled.header`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 0px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Footer = styled.footer`
background-color: #222;
color: #fff;
padding: 12px 0px 12px 0px;
text-align: center;
left: 0;
bottom: 0;
width: 100%;
margin-top:200px;
`;

export const StyledPostWrapper = styled.div`
  margin: 12px 0px 12px 0px;
`;


export const LocalEvento = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0px 5px 10px;
`;


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // background-color:${props => props.backgroundcolor};
  margin: 12px 0px 12px 0px;
`;

export const PostImage = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 0px;
`;

export const PostTitle = styled.h1`
position: absolute;
font-size: 24px;
top: -15.5px;
left: 0;
width: 100%;
background-color: ${props => props.backgroundcolor};
color: #fff;
padding: 6px 0px 6px 8px;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: flex-start;
`;

export const StyledTitle = styled.h4`
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0px -10px 15px;

`;

export const PostContentPreview = styled.div`
  background-color:${props => props.backgroundcolor};
  font-size: 20px;
  padding-left:12px;
  padding-right:12px;
  margin-top: 8px;
  `;
  
  export const LineContentPreview = styled.div`
  background-color:${props => props.backgroundcolor};
  font-size: 20px;
  padding-left:12px;
  padding-right:12px;
  margin-top: -15px;
`;

export const StyledImage = styled.img`
max-width: 100%;
max-height: 100%;
position: relative;
`;

export const Container = styled.div`
 display: grid;
 grid-template-columns: 1fr auto;
//  align-items: right;
//  text-align: right;
text-align: center;
float: right;
margin-right: 5%;
 `;
 
export const StyledLink = styled.a`
  display:block;
  width:100px;
  color: #eee;
  background-color: #888;
  font-weight: bold;
  font-size: 19px;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 0px 8px 0px;
  border-radius:4px;
  &:hover {
    opacity: 0.8;
  }
`;
