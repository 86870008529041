import React from 'react';
import styled from 'styled-components';

// Define the styled component
export const StyledButton = styled.button`
  background-color: #f44336;
  color: #ffffff;
  font-size: 17px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;
export const PatrocinioBanner = styled.img`

`;

// Define the styled component
export const Patrocinio = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 12px 0px;
  background-color: #222;
  color: #fff;
  padding: 12px 0px 12px 0px;
  text-align: center;

  left: 0;
  bottom: 0;
`;


export const Container = styled.div`
display: grid;
grid-template-columns: 1fr auto;
align-items: center;
margin-right: 20px;
`;


export const StyledTitle = styled.h1`
  text-transform: uppercase;
  font-size: 27px;
  margin-left:10px;
  margin-bottom: 5px;
  /* Add your additional styling here */
`;


export const Separator = styled.hr`
  border: none;
  border-top: 3px solid #555;
  margin: 0px 0px 20px 0px;
  margin-left: 10px;
`;


// Define the styled component
export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d42326;
  color: #ffffff;
  font-size: 16px;
  padding: 0px 0px 10px 0px;
  border: none;
  cursor: pointer;
`;

export const LogoRadar = styled.img`
  margin-right: 25px;
  width: 70%;
`;

// Define the styled component
export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #222;
  color: #fff;
  padding: 12px 0px 12px 0px;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const Wrapper = styled.div`
  background-color: 'black';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

