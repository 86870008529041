import React from 'react';
import { MenuCanaisWrapper, Container } from './styles.js';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
// import {logoAddress }from "../constants.js"



const MenuCanais = () => {
  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Container backgroundcolor='#e00000' mb='5px' fw='bold'>CANAIS</Container>
      <MenuCanaisWrapper>
        <Container backgroundcolor='#1f71b9aa' mb='5px'onClick={() => handleNavigation("/agenda")}>agenda</Container>
        <Container backgroundcolor='#2c5c22aa' mb='5px'onClick={() => handleNavigation("/comer-e-beber")}>comer e beber</Container>
        <Container backgroundcolor='#8d3300aa' mb='5px'onClick={() => handleNavigation("/arte-e-cultura")}>arte e cultura</Container>
        <Container backgroundcolor='#4200ffaa' mb='5px'onClick={() => handleNavigation("/lugares-para-visitar")}>lugares para visitar</Container>
        <Container backgroundcolor='#181717aa' mb='5px'onClick={() => handleNavigation("/radar-secreto")}>radar secreto</Container>
        <Container backgroundcolor='#1f71b9aa' mb='5px'onClick={() => handleNavigation("/feiras-e-eventos")}>feiras e eventos</Container>
        <Container backgroundcolor='#4200ffaa' mb='5px'onClick={() => handleNavigation("/compras")}>compras</Container>
        <Container backgroundcolor='#2c5c22aa' mb='5px'onClick={() => handleNavigation("/roteiros")}>roteiros</Container>
        <Container backgroundcolor='#8d3300aa' onClick={() => handleNavigation("/radar-da-linha")}>radar da linha</Container>
      </MenuCanaisWrapper>
    </div>
    );
  }

export default MenuCanais;