import React from 'react';

import {
  Wrapper,
    Header,
    Footer,
    LogoRadar,
} from "./styles"

import CanalMobile from '../ChamadaMobile/ChamadaMobile'
// import ToggleMenu from '../ToggleMenu/ToggleMenu';
import RoutedToggleMenu from '../RoutedToggleMenu/RoutedToggleMenu';
import Capa from '../Capa';
import { siteAddress } from '../constants';


function About() {
  let logoAddress = 'https://' +siteAddress+ '/wp-content/uploads/2023/07/Logo-branco-fundo-Transparente.png'
  return (
    <Wrapper>
      <Header>
        <LogoRadar src={logoAddress} />
        <RoutedToggleMenu />
      </Header>
      <Capa />
      <div>
        <h1>About</h1>
        <p>Here is a page about the Radar360</p>
        <a>Know more</a>
      </div>
      <CanalMobile categoria='turismo'/>
      {/*<CanalMobile categoria='radar-secreto'/> */}
      <Footer>
      <p>&copy; 2023 My Website. All rights reserved.</p>
      </Footer>
    </Wrapper>
  );
};

export default About;