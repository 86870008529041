// export const siteAddress = "192.168.1.102:8000"
export const siteAddress = "spguide.com.br"

// export const logoAddress = 'https://spguide.com.br/wp-content/uploads/2023/09/Logo-radar-branco-fundo-Transparente.png'
// let logoAddress = 'https://' +siteAddress+ '/wp-content/uploads/2023/09/Logo-radar-branco-fundo-Transparente.png'
const imageName = '/wp-content/uploads/2023/11/Logo-Radar-SP-Guide-V_2.png'
export const logoAddress = 'https://' +siteAddress+imageName 
export const apoieAddress = 'https://' +siteAddress+ '/wp-content/uploads/2024/01/Apoio-Banner.webp'


export const linhas = ['1 Azul', '2 Verde', '3 Vermelha', '4 Amarela', '5 Lilás', '8 Diamante', '9 Esmeralda']
export const linhasTags = ['1 azul', '2 verde', '3 vermelha', '4 amarela', '5 lilás', '8 diamante', '9 esmeralda']
export const linhasSlugs = ['1-azul', '2-verde', '3-vermelha', '4-amarela', '5-lilas', '8-diamante', '9-esmeralda']

export const categoriesStyles = {
    chamada_arte_e_cultura: {
      backgroundcolor: '#ffffffff',
      backgroundcolorTitle: '#2c5c22aa',
      titulo: 'Arte & Cultura',
      tags: ['museus','teatros','centros culturais', 'bibliotecas/livrarias', 'galerias de arte', 'feiras de arte', 'casas de show'],
    },      
    arte_e_cultura: {
      backgroundcolor: '#2c5c22',
      backgroundcolorTitle: '#2c5c22aa',
      titulo: 'Arte & Cultura',
      tags: ['museus', 'centros culturais', 'teatros', 'bibliotecas/livrarias', 'galerias e feiras', 'casas de show'],
    },    
    chamada_lugares_para_visitar: {
      backgroundcolor: '#00aaff',
      backgroundcolorTitle: '#00aaffaa',
      tags: ['hoje','amanha','semana'],
    },     
    chamada_metro: {
      backgroundcolor: '#00aaff00',
      backgroundcolorTitle: '#00aaffaa',
      tags: ['hoje','amanha','semana'],
    },    
    lugares_para_visitar: {
      backgroundcolor: '#4200ffaa',
      backgroundcolorTitle: '#4200ffaa',
      tags: ['mirantes','ruas e avenidas','parques e praças','prédios icônicos','monumentos','templos religiosos'],
    },
    chamada_agenda: {
      backgroundcolor: '#aa222200',
      backgroundcolorTitle: '#e00000aa',
      tags: ['essa semana', 'fim de semana', 'este mês'],
    },
    destaques_da_agenda: {
      backgroundcolor: '#e0000000',
      backgroundcolorTitle: '#e00000aa',
      tags: ['',''],
    },
    agenda: {
      backgroundcolor: '#e00000',
      backgroundcolorTitle: '#e00000aa',
      tags: ['toda agenda','próximos 30 dias'],
    },
    chamada_feiras_e_eventos: {
      backgroundcolor: '#00000000',
      backgroundcolorTitle: '#008800cc',
      tags: ['feiras de negócios', 'eventos'],
    },
    feiras_e_eventos: {
      backgroundcolor: 'green',
      backgroundcolorTitle: '#008800cc',
      tags:['janeiro', 'fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
    }, 
    roteiros: {
      backgroundcolor: 'green',
      backgroundcolorTitle: '#008800cc',
      tags:['av paulista', 'bairro da liberdade', 'centro historico', 'centro novo', 'parque ibirapuera', 'rua augusta'],
    },    
    compras: {
      backgroundcolor: 'green',
      backgroundcolorTitle: '#008800cc',
      tags:['shoppings', 'comércio de rua'],
    },
    chamada_comer_e_beber: {
      backgroundcolor: '#aa334400',
      backgroundcolorTitle: '#aa1122aa',
      tags:['bar/boteco','restaurante','padaria','pizzaria'],
    },
    comer_e_beber: {
      backgroundcolor: '#aa3344',
      backgroundcolorTitle: '#aa1122aa',
      tags:['restaurantes', 'bares e botecos', 'padarias','pizzarias', 'cafés','lanchonetes'],
    },
    radar_secreto: {
      backgroundcolor: '#aa3344',
      backgroundcolorTitle: '#aa1122aa',
      tags:[''],
    },
    radar_da_estacao: {
      backgroundcolor: '#aa3344',
      backgroundcolorTitle: '#aa1122aa',
      tags:[''],
    },
    radar_da_linha: {
      backgroundcolor: '#aa3344',
      backgroundcolorTitle: '#aa1122aa',
      tags: linhasTags,
    },
    chamada_radar_secreto: {
      backgroundcolor: '#aa334400',
      backgroundcolorTitle: '#aa1122aa',
      tags:['bar/boteco','restaurante','padaria','pizzaria'],
    },
  };


export const iconesList = {
  tel: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Telefone.png',
  site: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Site.png',
  facebook: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Facebook-preto.png',
  instagram: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Instagram.png',
  cptm: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-CPTM-preto.png',
  metro: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Metro.png',
  compartilhar: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-compartilhar-preto-vazado-2.png',
  estacionamento: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Estacionamento.png',
  cardapio: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Cardapio.png',
  pet: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Pet.png',
  ciclovia: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-SERVICO-Ciclovia.png',
  taxi99: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-99.png',
  uber: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-Uber.png',
  waze: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-waze.png',
  google_maps: 'https://'+siteAddress+'/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-Google-Maps-1.png',
  programacao: 'https://'+siteAddress+'/wp-content/uploads/2023/09/Icone-SERVICO-Programacao.png',
  ingressos: 'https://'+siteAddress+'/wp-content/uploads/2023/09/Icone-SERVICO-Ingressos.png',
  lojas: 'https://'+siteAddress+'/wp-content/uploads/2023/11/Icone-SERVICO-Lojas.png',
  credenciamento: 'https://'+siteAddress+'/wp-content/uploads/2023/11/Icone-SERVICO-Credenciamento.png',
  cinemas: 'https://'+siteAddress+'/wp-content/uploads/2024/01/Icone-Cinema.png',
  artistas: 'https://'+siteAddress+'/wp-content/uploads/2024/01/Icone-Artistas.png',
  outros: '',
} 


export const metroStations = {
  '1 - Blue Line': [
    'Jabaquara',
    'Conceição',
    'São Judas',
    'Saúde',
    'Praça da Árvore',
    'Santa Cruz',
    'Vila Mariana',
    'Ana Rosa',
    'Paraíso',
    'Vergueiro',
    'São Joaquim',
    'Liberdade',
    'Sé',
    'São Bento',
    'Luz',
    'Tiradentes',
    'Armênia',
    'Tietê',
    'Carandiru',
    'Santana',
    'Jardim São Paulo',
    'Parada Inglesa',
    'Tucuruvi'
  ],
  '2 - Green Line': [
    'Vila Prudente',
    'Tamanduateí',
    'Sacomã',
    'Alto do Ipiranga',
    'Santos-Imigrantes',
    'Chácara Klabin',
    'Ana Rosa',
    'Paraíso',
    'Brigadeiro',
    'Trianon-Masp',
    'Consolação',
    'Clínicas',
    'Sumaré',
    'Vila Madalena'
  ],
  '3 - Red Line': [
    'Corinthians-Itaquera',
    'Artur Alvim',
    'Patriarca-Vila Ré',
    'Guilhermina-Esperança',
    'Vila Matilde',
    'Patriarca',
    'Penha',
    'Carrão',
    'Tatuapé',
    'Belém',
    'Bresser-Mooca',
    'Brás',
    'Pedro II',
    'Sé',
    'Anhangabaú',
    'República',
    'Santa Cecília',
    'Marechal Deodoro',
    'Palmeiras-Barra Funda'
  ],
  '4 - Yellow Line': [
    'Luz',
    'República',
    'Higienópolis-Mackenzie',
    'Paulista',
    'Oscar Freire',
    'Faria Lima',
    'Pinheiros',
    'Butantã',
  ],
  '5 - Lilac Line': [
    'Capão Redondo',
    'Campo Limpo',
    'Vila das Belezas',
    'Giovanni Gronchi',
    'Santo Amaro',
    'Largo Treze',
    'Adolfo Pinheiro',
    'Aldo da Boa Vista',
    'Borba Gato',
    'Broklin',
    'Eucaliptos'
  ],
  '15 - Silver Line': [
    'Vila Prudente',
    'Oratório',
    'São Lucas',
    'Camilo Haddad',
    'Vila Tolstói',
    'Vila União',
    'Jardim Planalto',
    'São Mateus',
    'Iguatemi',
    'São Rafael',
    'Hospital Cidade Tiradentes',
    'Cidade Tiradentes'
  ],
  // Add more lines and stations here
};