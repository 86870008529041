import React from 'react';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';




// Define the styled component
export const SelectorWrapper = styled.div`
flex:1;
margin: 0px 0px 0px 0px;
margin-left: 8%;

`;


export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  
  select {
    background-color: #320049;
    color: #fff;
    border-radius: 4px;
    width: 55%;
    height: 50px;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
  }
`;

export const StyledOption = styled.option`
padding: 20px 10px 210px 10px;
font-size:20px;

` 

export const ArrowIcon = styled(FaArrowLeft)`
margin-bottom:0px;
/* Add any additional styles for the icon here */
`;

export const LargeArrowIcon = styled(ArrowIcon)`
font-size: 26px;
margin-bottom:-15px;
  /* Add any additional styles for the icon here */
`;
