import React, { useEffect, useState } from 'react';

import {Wrapper, StyledImage, PostContentPreview} from './styles'
import { categoriesStyles, siteAddress } from '../../Mobile/constants';


const Capa = () => {
  const [latestPost, setLatestPost] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const categorySlug = 'capa';


  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        // Replace <category_slug> with the slug of the desired category
        

        // Get the category ID using the slug
        const categoryResponse = await fetch(`https://${siteAddress}/wp-json/wp/v2/categories?slug=${categorySlug}`);
        const categoryData = await categoryResponse.json();
        const categoryId = categoryData[0]?.id;
        console.log(categoryId)

        // Fetch the most recent post in the category
        const postResponse = await fetch(`https://${siteAddress}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=1&_embed`);
        const postData = await postResponse.json();
        setLatestPost(postData[0]);

        if (postData[0].featured_media) {
          const mediaResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/media/${postData[0].featured_media}`);
          const mediaData = await mediaResponse.json();
          setImageUrl(mediaData.source_url);
        }


      } catch (error) {
        console.error(error);
      }
    };

    fetchLatestPost();
  }, []);

  const filteredSlug = categorySlug.replace(/-/g,'_')
  const nestedDictionary = categoriesStyles[filteredSlug];

  return (
    <Wrapper backgroundcolor='#7800d0'>
      {latestPost ? (
        <div>
          {imageUrl && <StyledImage src={imageUrl} alt="Post Image" />}
          <PostContentPreview>{removeHTMLTagsUsingDOM(latestPost.content.rendered)}</PostContentPreview> 
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Wrapper>
  );
};

export default Capa;

function removeHTMLTagsUsingDOM(text) {
  const doc = new DOMParser().parseFromString(text, 'text/html');
  return doc.body.textContent || "";
}

function removeHTMLTags(text) {
  return text.replace(/<[^>]*>/g, ''); // Regex to remove HTML tags
}