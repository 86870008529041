import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color:${props => props.backgroundcolor};
  margin: 10px 0px 12px 0px;
`;

export const GoogleMapsImage = styled.img`
max-width: 40%;
max-height: 100%;
margin: 10px 0px 10px 10px;
`;

export const StyledImage = styled.img`
max-width: 13%;
max-height: 100%;
margin: 0px 0px 10px 10px;
`;

export const ComoChegarTitle = styled.h1`
  font-size: 24px;
  margin: 30px 0px 0px 10px;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 3px solid #222;
  margin: 0px 0px 20px 0px;
  margin-left: 10px;
`;