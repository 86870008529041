import React from 'react';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';


// Define the styled component
export const Patrocinio = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 12px 0px;
  background-color: #320049;
  color: #fff;
  padding: 12px 0px 12px 0px;
  text-align: center;

  left: 0;
  bottom: 0;

`;


export const WrapperSelectorAgenda = styled.div`
  flex:1;
  margin: 0px 0px 0px 0px;
  margin-left: 8%;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  
  select {
    background-color: #320049;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    width: 45%;
    height: 50px;
    font-size: 20px;
  }
`;


export const StyledTitle = styled.h1`
  text-transform: uppercase;
  font-size: 27px;
  margin-left:10px;
  margin-bottom: 5px;
  /* Add your additional styling here */
`;

export const Separator = styled.hr`
  border: none;
  border-top: 3px solid #555;
  margin: 0px 0px 20px 0px;
  margin-left: 10px;
`;


export const Container = styled.div`
display: grid;
grid-template-columns: 1fr auto;
align-items: center;
margin-right: 20px;
`;

export const ArrowIcon = styled(FaArrowLeft)`
margin-bottom:0px;
/* Add any additional styles for the icon here */
`;

export const LargeArrowIcon = styled(ArrowIcon)`
font-size: 26px;
margin-bottom:-15px;
  /* Add any additional styles for the icon here */
`;
