import React from 'react';
import { ComoChegarTitle, GoogleMapsImage, Separator, StyledImage } from './styles';

const ComoChegar = ({ address }) => {
  const openMaps = () => {
    if (address) {
      const coordinates = getCoordinatesForAddress(address);
      if (coordinates) {
        const mapsUrl = `http://maps.google.com/?q=${address}`;
        window.open(mapsUrl, '_blank');
      } else {
        alert('Invalid address. Please provide a valid address.');
      }
    } else {
      alert('No address provided.');
    }
  };  
  
  const open99 = () => {
    if (address) {
      const coordinates = getCoordinatesForAddress(address);
      if (coordinates) {
        const t99Url = `uber://?action=setPickup&pickup=my_location&dropoff[latitude]=${coordinates.latitude}&dropoff[longitude]=${coordinates.longitude}`;
        window.open(t99Url, '_blank');
      } else {
        alert('Invalid address. Please provide a valid address.');
      }
    } else {
      alert('No address provided.');
    }
  };  
  const openUber = () => {
    if (address) {
      const coordinates = getCoordinatesForAddress(address);
      if (coordinates) {
        const uberUrl = `uber://?action=setPickup&pickup=my_location&dropoff[latitude]=${coordinates.latitude}&dropoff[longitude]=${coordinates.longitude}`;
        window.open(uberUrl, '_blank');
      } else {
        alert('Invalid address. Please provide a valid address.');
      }
    } else {
      alert('No address provided.');
    }
  };

  const open99Generic = () => {
  
    const url99 = `taxis99://`;
    window.open(url99, '_blank');
  };
  const openUberGeneric = () => {
        const uberUrl = `uber://`;
        window.open(uberUrl, '_blank');
  };
  const openWazeGeneric = () => {
        const wazeUrl = `https://waze.com/`;
        window.open(wazeUrl, '_blank');
  };

  const openWaze = () => {
    if (address) {
      const coordinates = getCoordinatesForAddress(address);
      if (coordinates) {
        const wazeUrl = `https://waze.com/ul?ll=${coordinates.latitude},${coordinates.longitude}&navigate=yes`;
        window.open(wazeUrl, '_blank');
      } else {
        alert('Invalid address. Please provide a valid address.');
      }
    } else {
      alert('No address provided.');
    }
  };

  const getCoordinatesForAddress = (address) => {
    // Use a geocoding service (e.g., Google Maps Geocoding API) to get coordinates for the address
    // Replace this with your actual geocoding implementation
    // Example URL: `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=YOUR_API_KEY`
    // Handle the API request and parse the response to get the coordinates
    // Return an object with `latitude` and `longitude`
    // For simplicity, you can hardcode coordinates or use a geocoding library
    return { latitude: 123.456, longitude: -789.012 };
  };

  return (
    <div>
      <ComoChegarTitle>COMO CHEGAR</ComoChegarTitle>
      <div>
        <GoogleMapsImage
          src="https://spguide.com.br/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-Google-Maps-1.png"
          alt="Open Maps"
          onClick={openMaps}
          style={{ cursor: 'pointer' }}
        />
        <Separator />

        <StyledImage
          src="https://spguide.com.br/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-Uber.png"
          alt="Open Uber"
          onClick={openUberGeneric}
          style={{ cursor: 'pointer' }}
        />
        <StyledImage
          src="https://spguide.com.br/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-waze.png"
          alt="Open Waze"
          onClick={openWazeGeneric}
          style={{ cursor: 'pointer' }}
        />        
        <StyledImage
          src="https://spguide.com.br/wp-content/uploads/2023/08/Icone-COMO-CHEGAR-99.png"
          alt="Open 99"
          onClick={open99Generic}
          style={{ cursor: 'pointer' }}
        />
        {/* Include images for other navigation services as needed */}
      </div>
    </div>
  );
};

export default ComoChegar;
