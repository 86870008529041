import React, { useEffect, useState } from 'react';

import {
  Wrapper, 
  StyledImage, 
  PostTitle, 
  PostContentPreview, 
  StyledPostWrapper, 
  StyledLink, 
  Container, 
  PostImage,
  StyledTitle,
  LineContentPreview,
  LocalEvento} from './styles'
import { categoriesStyles, iconesList, siteAddress, linhasSlugs } from '../constants';


import * as utils from './utils.js';


const PostList = (props) => {

  // const [openStates, setOpenStates] = useState([]);
  const [postList, setPostList] = useState(null);
  const [postsCanal, setPostsCanal] = useState({});
  // const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.PerPage == null ? 99 : props.PerPage);
  
  // New state to control the loading state
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  
  // Variables to control the infinite scroll
  const categorySlug = props.categoria;
  const mainCategorySlug = props.categoria;
  let subCategorySlug = props.subCategoria;
  let agendaDate = props.agendaDate;

  let listaDeConteudo = []
  // categorySlug = props.subCategoria;
  console.log("Started Post list")
  
  
  // Check changes to reload the page
  useEffect(() => {
    setLoading(true);
    fetchLatestPost();
  }, []);

  const fetchLatestPost = async () => {
    try {
      if (categorySlug == 'agenda') {
        subCategorySlug = ''
      }
      console.log("1 - subCategorySlug", subCategorySlug);
      subCategorySlug == '5-lilás' ? subCategorySlug = '5-lilas' : subCategorySlug = subCategorySlug
      let slug = '' 
      // Apply the function to each item in the array
      const filteredSlug = categorySlug.replace(/-/g,'_')
      let filterLists = [categoriesStyles[filteredSlug].tags]
      let cleanSlugs = [categoriesStyles[filteredSlug].cleanTags]
      let filterListsClean = filterLists[0].map((item => {
         return utils.removeAccentsAndSpaces(item);
      }));
      // console.log("Clean filter post list", filterListsClean)
      console.log("filteredSlug", filteredSlug)
      console.log("filterListsClean", filterListsClean);
      console.log("subCategorySlug", subCategorySlug);
      console.log("cleanSlugs", cleanSlugs);
      const isValueInArray = cleanSlugs[0].includes(subCategorySlug);
      // const isValueInArray = filterListsClean.includes(subCategorySlug.replace(/-/g, ' '));
      console.log("isValueInArray", isValueInArray)

      if (subCategorySlug != 'categoria' && subCategorySlug != 'linha' && subCategorySlug != '' && isValueInArray) {
        slug = subCategorySlug
        console.log("slug = subCategorySlug")
      } else {
        slug = categorySlug
      }
      categorySlug == 'radar-da-linha' && !linhasSlugs.includes(subCategorySlug) ? slug = subCategorySlug = '' : slug=slug
      // categorySlug == 'radar-da-linha' ? subCategorySlug = '' : subCategorySlug=subCategorySlug
      // console.log("First slugs")
      console.log("My categorySlug: ", categorySlug)
      // console.log("My slug: ", slug)
      // console.log("My subSlug: ", subCategorySlug)
      // Get the category ID using the slug

      let linhaResponse = []
      let linhaData = []
      let linhasID = []
      if (categorySlug == 'radar-da-linha' && subCategorySlug == '') {
        for (let linha of linhasSlugs) {
          linha = utils.filterSlug(linha)
          console.log('Minha linha:', linha)
          linhaResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/categories?slug=${linha}`);
          linhaData = await linhaResponse.json();
          linhasID.push(linhaData[0]?.id)
          linhaResponse = []
          linhaData = []
        }
        // console.log('Minhas linhas:', linhasID)
      }

      const categoryResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/categories?slug=${slug}`);
      const categoryData = await categoryResponse.json();
      const categoryId = categoryData[0]?.id;
      console.log("Category ID:", categoryId)
      // Fetch the posts at the category ID
      let postResponse = []
      categorySlug == 'radar-da-linha' && subCategorySlug == ''
      ? postResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/posts?categories=${linhasID.join('&')}&per_page=${perPage}&page=${page}&_embed&orderby=title&order=asc`)
      : postResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&_embed&orderby=title&order=asc`)
      
      const postData = await postResponse.json();
      setPostList(postData)
      let meuConteudo = []
      for (const post of postData) {
        console.log('Meu post:', post)
        meuConteudo.FeaturedMedia = post._embedded?.['wp:featuredmedia']
        meuConteudo.Title = post.title.rendered
        meuConteudo.Dictionary = utils.createDictionaryFromContent(post.content.rendered)
        meuConteudo.Contato = utils.getServices(meuConteudo.Dictionary.CONTATO)

        try {

          if (meuConteudo.Contato[0].hasOwnProperty('text')){
            if (meuConteudo.Contato[0].text == 'tel'){
              meuConteudo.Contato[0].ligar = meuConteudo.Contato[1].text == 'ligar' ? 'tel:'+meuConteudo.Contato[1].link : 'none'
            }
          }
        } catch(error) {

          console.log('error - ', error)
        }

        meuConteudo.Dictionary.LOCAL = utils.cleanHTML(meuConteudo.Dictionary.LOCAL)
        console.log('Meu conteudo:', meuConteudo)
        meuConteudo.Inicio = utils.cleanDate(meuConteudo.Dictionary.INICIO)
        meuConteudo.Fim = utils.cleanDate(meuConteudo.Dictionary.FIM)
        meuConteudo.LocalEvento =  meuConteudo.Dictionary.hasOwnProperty('LOCAL EVENTO') ? utils.removeSpecialChar(utils.removeHTML(meuConteudo.Dictionary['LOCAL EVENTO'])) : ''
        if (!listaDeConteudo.some(item => item === meuConteudo)) {
          listaDeConteudo.push(meuConteudo);
        }
        meuConteudo = []
      }
      console.log("agenda subcategory", props.subCategoria)
      if (props.categoria == 'agenda' && props.subCategoria != 'toda agenda'){
        const newList = utils.filterAgendaContent(listaDeConteudo, props.agendaDate)
        listaDeConteudo = newList
      }
      setPostsCanal(listaDeConteudo)
      // setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
    
  }
  // ------------------> End of fetchLatestPost function
  
  // Function to detect scroll to the bottom
  // const handleScroll = () => {
  //   if (!loading && hasMore && window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && categorySlug != 'destaques-da-agenda') {
  //     fetchLatestPost();
  //   }
  // };
  
  // Remove the scroll event listener when the component unmounts
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [loading, hasMore]);

  const filteredSlug = mainCategorySlug.replace(/-/g,'_')
  const nestedDictionary = categoriesStyles[filteredSlug]; 

  return (
    <Wrapper backgroundcolor={nestedDictionary.backgroundcolor}>
      {postList ? (postsCanal.map((post, index) => (
        <div key={post.Title}>
          <utils.Post 
            key={index} 
            post={post} 
            category={props.categoria}
            nestedDictionary={nestedDictionary}
            // isOpen={openStates[index]}
            // toggle={() => toggle(index)}            
          />
        </div>
      ))) : (
      <p>Loading...</p>
      )} 
      {/* <PostListStates postList={postList} posts={postsCanal} nestedDictionary={nestedDictionary}/> */}

    </Wrapper>
  );
};

export default PostList;