import React from 'react';
import styled from 'styled-components';


export const ColumnsContainer = styled.div`
  display: flex;
  margin-left: 7%;
  margin-right: 9.5%;
  justify-content: space-between; /* Adjust alignment as needed */
`;

export const Column = styled.div`
  flex: 1; /* Each column takes equal space */
  padding: 10px;
  margin: 5px;
`;


// Define the styled component
export const StyledButton = styled.button`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Header = styled.header`
  display: flex;
  position: fixed;
  align-items: center;
  // background-color: #d42326;
  background-color: #7800d0;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 0px 10px 0px;
  border: none;
  z-index: 100;
  width: 100%;
`;

export const Brace = styled.div`
display: flex;
align-items: center;
background-color: #ffffff00;
color: #ffffff;
font-size: 16px;
padding: 10px 0px 10px 0px;
border: none;
cursor: pointer;

`

export const LogoRadar = styled.img`
  width: 20%;
  margin-left: 7.5%;
  cursor: pointer;
`;

// Define the styled component
export const Patrocinio = styled.div`
  display: flex; 
  color: #fff;
  background-color: #aa00aa;
  width: 100%;
  height: 70px;
`;

export const PatrocinioLogoRadar = styled.img`
  float: right;
  margin-right: 1.5%;
  width: 65%;
  height: auto;
`;

// Define the styled component
export const FooterWrapper = styled.footer`
display: flex;
flex-direction: column;

margin: 12px 0px 12px 0px;
background-color: #222;
color: #fff;
padding: 12px 0px 12px 0px;
text-align: center;

left: 0;
bottom: 0;
width: 100%;
`;

export const Wrapper = styled.div`
  background-color: 'black';
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

export const Separator = styled.hr`
  border: none;
  border-top: 3px solid #555;
  margin-bottom: -10px;
  margin-left: 7.9%;
  margin-right: 10.5%;
`;