import React, {  useState } from 'react';
import { DropdownContainer, WrapperSelectorAgenda} from './styles';
import EventoDate from '../EventoDate/EventoDate';


function getDateRanges() {
  const today = new Date();
  const currentDay = today.getDay();
  const startDate = new Date(today);
  const endDate = new Date(today);

  // Calculate the start date (last Monday)
  const start = ((currentDay - 1) < 0) ? 6 : (currentDay - 1);
  console.log('START', currentDay)
  console.log('START', today)
  startDate.setDate(today.getDate() - start);
  console.log('START', startDate)

  // Calculate the end date (next Sunday)
  const end = (currentDay === 0) ? 0 : (7 - currentDay);
  endDate.setDate(today.getDate() + end);

  const nextWeekStartDate = new Date(startDate);
  const nextWeekEndDate = new Date(endDate);

  // Calculate the start date for próxima semana (next Monday)
  nextWeekStartDate.setDate(endDate.getDate() + 1);
  // Calculate the end date for próxima semana (next Sunday)
  nextWeekEndDate.setDate(nextWeekStartDate.getDate() + 6);

  const next30DaysStartDate = new Date(today);
  const next30DaysEndDate = new Date(today);
  const next365DaysEndDate = new Date(today);
  
  // Calculate the start date for the next 30 days
  next30DaysEndDate.setDate(today.getDate() + 29);
  next365DaysEndDate.setDate(today.getDate() + 665);

  return [
    // {
    //   label: 'esta semana',
    //   startDate,
    //   endDate,
    // },
    {
      label: 'toda agenda',
      startDate: next30DaysStartDate,
      endDate: next365DaysEndDate,
    },
    {
      label: 'próximos 30 dias',
      startDate: next30DaysStartDate,
      endDate: next30DaysEndDate,
    },
  ];
}


function isDateInRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate;
}


const AgendaSelector = ({callback}) => {
  const dateRanges = getDateRanges();
  
  const [selectedRange, setSelectedRange] = useState('toda agenda');


  const handleRangeChange = (event) => {
    console.log("selected option", event.target.value)
    setSelectedRange(event.target.value);
    let newDateRange = dateRanges.find((range) => range.label === event.target.value)
    console.log("Date range", newDateRange)
    callback(event.target.value, newDateRange)
  };

  const selectedDateRange = dateRanges.find((range) => range.label === selectedRange);


  return (
    <WrapperSelectorAgenda>
        <DropdownContainer >
          <select
            id="dateRangeSelector"
            onChange={handleRangeChange}
            value={selectedRange}
          >
            {dateRanges.map((range) => (
              <option key={range.label} value={range.label}>
                {range.label}
              </option>
            ))}
          </select>
        </DropdownContainer>
              <EventoDate date={selectedDateRange.startDate.toISOString().split('T')[0]} dateE={selectedDateRange.endDate.toISOString().split('T')[0]} 
              original={true} label={selectedDateRange.label}/>
              {selectedDateRange && (
          <div>

          </div>
        )}

    </WrapperSelectorAgenda>
  );
};

export default AgendaSelector;


{/* <p>Start Date: {selectedDateRange.startDate.toISOString().split('T')[0]}</p>
<p>End Date: {selectedDateRange.endDate.toISOString().split('T')[0]}</p>
<p>
  Date {dateToCheck.toISOString().split('T')[0]} is in this range:{' '}
  {isDateInRange(dateToCheck, selectedDateRange.startDate, selectedDateRange.endDate)
    ? 'Yes'
    : 'No'}
</p> */}