// IconList.js
import React from 'react';
import "./ContactList.css"

const ContactList = ({ items }) => {
  let ligar = ''
    items.map((item, index) => {
      if (item.text == "ligar") {
        // ligar = 'tel:'+item.link
        // console.log("LIGAAAAAR", ligar)
        delete items[index]
       } 
      });
    console.log("ITEMS:", items)
    console.log("LIGAR:", ligar)
    return (
      <ul className="icon-list">
        {items.map((item, index) => (
        <div key={index}>
        {!item.isTel ?
          <a className='contact-link'   target='blank' href={item.link}>
            <li className="icon-list-item">
              <div className="icon">
                  <img className="icon-image" src={item.imageUrl} alt={item.text} />
                </div>
              <div className="text">{item.text}</div>
            </li>
          </a> :
          <a className='contact-link'   target='blank' href={item.ligar}>
            <li className="icon-list-item">
              <div className="icon">
                <img className="icon-image" src={item.imageUrl} alt={item.text} />
              </div>
              <div className="text">{item.link}</div>
            </li>
          </a> 
          }
        </div>
        ))}
      </ul>
    );
  }

export default ContactList;