import React, { useEffect, useState } from 'react';

import {
  Wrapper, 
  StyledImage, 
  PostTitle, 
  PostContentPreview, 
  StyledPostWrapper, 
  StyledLink, 
  Container, 
  PostImage,
  StyledTitle,
  LineContentPreview,
  LocalEvento} from './styles'
import { categoriesStyles, iconesList, siteAddress, linhasSlugs } from '../constants';
import ContactList from '../ContactList/ContactList';
import ServicesList from '../ServicesList/ServiceList';
import ComoChegar from '../ComoChegar/ComoChegar';
import EventoDate from '../EventoDate/EventoDate';
import PostDate from '../EventoDate/PostDate';


const PostList = (props) => {
  const [postList, setPostList] = useState(null);
  const [postsCanal, setPostsCanal] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.PerPage == null ? 99 : props.PerPage);
  const categorySlug = props.categoria;
  const mainCategorySlug = props.categoria;
  let subCategorySlug = props.subCategoria;
  let agendaDate = props.agendaDate;
  // categorySlug = props.subCategoria;

  console.log("My categorySlug: ", categorySlug)
  console.log("My subCategorySlug: ", subCategorySlug)
  console.log("SubCategory: ", props.subCategoria)

  // const perPage = 10;

  useEffect(() => {
    subCategorySlug = categorySlug;
    console.log("ENTROU!!")
  }, [categorySlug]);


  useEffect(() => {
    const fetchLatestPost = async () => {
      try {
        if (categorySlug == 'agenda') {
          subCategorySlug = ''
        }
        let slug = '' 
        if (subCategorySlug != 'categoria' && subCategorySlug != 'linha' && subCategorySlug != '' ) {
          // categoriesStyles[mainCategorySlug.replace(/-/g,'_')].tags.map()
          slug = subCategorySlug
          console.log("slug = subCategorySlug")
        } else {
          slug = categorySlug
          console.log("slug = categorySlug")
        }
        categorySlug == 'radar-da-linha' && !linhasSlugs.includes(subCategorySlug) ? slug = subCategorySlug = '' : slug=slug
        // categorySlug == 'radar-da-linha' ? subCategorySlug = '' : subCategorySlug=subCategorySlug
        console.log("First slugs")
        console.log("My categorySlug: ", categorySlug)
        console.log("My slug: ", slug)
        console.log("My subSlug: ", subCategorySlug)
        // Get the category ID using the slug
        
        let linhaResponse = []
        let linhaData = []
        let linhasID = []
        console.log("Entradas ", categorySlug,subCategorySlug)
        if (categorySlug == 'radar-da-linha' && subCategorySlug == '') {
          for (let linha of linhasSlugs) {
            linha = filterSlug(linha)
            console.log('Minha linha:', linha)
            linhaResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/categories?slug=${linha}`);
            linhaData = await linhaResponse.json();
            linhasID.push(linhaData[0]?.id)
            linhaResponse = []
            linhaData = []
          }
          console.log('Minhas linhas:', linhasID)
        }

        const categoryResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/categories?slug=${slug}`);
        const categoryData = await categoryResponse.json();
        const categoryId = categoryData[0]?.id;
        console.log("Category ID:", categoryId)
        

        // Recover tag slug
        // https://spguide.com.br/wp-json/wp/v2/tags?slug=estacao-clinicas

        // Fetch the posts at the category ID
        let postResponse = []

        categorySlug == 'radar-da-linha' && subCategorySlug == ''
        ? postResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/posts?categories=${linhasID.join('&')}&per_page=${perPage}&page=${page}&_embed&orderby=title&order=asc`)
        : postResponse = await fetch(`https://`+siteAddress+`/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&_embed&orderby=title&order=asc`)
 
       
        // console.log("postResponse", postResponse)
        const postData = await postResponse.json();
        setPostList(postData)
        
        let meuConteudo = []
        let listaDeConteudo = []

        for (const post of postData) {
          // console.log("POST", post)
          meuConteudo.FeaturedMedia = post._embedded?.['wp:featuredmedia']
          meuConteudo.Title = post.title.rendered
          // console.log("MEU CONTEUDO", meuConteudo)
          meuConteudo.Dictionary = createDictionaryFromContent(post.content.rendered)
          // meuConteudo.Facilidades = getServices(meuConteudo.Dictionary.FACILIDADES)
          // console.log("RETURNED FACILIDADE", meuConteudo.Facilidades)
          meuConteudo.Contato = getServices(meuConteudo.Dictionary.CONTATO)
          // console.log('Contato', meuConteudo.Contato)
          // console.log('Contato [0]', meuConteudo.Contato[0])
          try {

            if (meuConteudo.Contato[0].hasOwnProperty('text')){
              if (meuConteudo.Contato[0].text == 'tel'){
                meuConteudo.Contato[0].ligar = meuConteudo.Contato[1].text == 'ligar' ? 'tel:'+meuConteudo.Contato[1].link : 'none'
              }
            }
          } catch(error) {
            // console.log('ERRORRRR', meuConteudo.Contato)
            // console.log('meuConteudo.CONTATO', meuConteudo.CONTATO)
            console.log('error - ', error)
          }
          // console.log("RETURNED CONTATO", meuConteudo.Contato)
          // console.log(Object.keys(meuConteudo))
          // console.log(Object.keys(meuConteudo.Dictionary))
          meuConteudo.Dictionary.LOCAL = cleanHTML(meuConteudo.Dictionary.LOCAL)
          meuConteudo.Inicio = cleanDate(meuConteudo.Dictionary.INICIO)
          meuConteudo.Fim = cleanDate(meuConteudo.Dictionary.FIM)
          // console.log("INICIO", meuConteudo.Dictionary.INICIO)
          // console.log("CONTENT", meuConteudo)
          meuConteudo.LocalEvento =  meuConteudo.Dictionary.hasOwnProperty('LOCAL EVENTO') ? removeSpecialChar(removeHTML(meuConteudo.Dictionary['LOCAL EVENTO'])) : ''
          // console.log("LOCAL EVENTO", meuConteudo.LocalEvento)
          if (!listaDeConteudo.some(item => item === meuConteudo)) {
            listaDeConteudo.push(meuConteudo);
          }
          meuConteudo = []
        }
        // console.log("Lista de Conteudo Keys", Object.keys(listaDeConteudo))
        // console.log("Lista de Conteudo", listaDeConteudo)
        setPostsCanal(listaDeConteudo)
        
        if (props.categoria == 'agenda' && props.subCategoria != 'toda agenda'){
          console.log('SubCategorySlug:', subCategorySlug)
          const newList = filterAgendaContent(listaDeConteudo, props.agendaDate)
          console.log(newList)
          setPostsCanal(newList)
        } else {
          setPostsCanal(listaDeConteudo)
        }
        
      } catch (error) {
        console.error(error);
      }
    };

    // Function to detect scroll to the bottom
    // const handleScroll = () => {
    //   if (
    //     window.innerHeight + window.scrollY >=
    //     document.documentElement.scrollHeight
    //     ) {
    //       // if (categorySlug != 'destaques-da-agenda'){
    //       setPerPage(prevPage => prevPage + 10);
    //       fetchLatestPost();
    //     // }
    //     }

    // };

    // Attach the scroll event listener
    fetchLatestPost();
    // window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      // window.removeEventListener('scroll', handleScroll);
    };

  }, [perPage, categorySlug, subCategorySlug, agendaDate]);




  const filteredSlug = mainCategorySlug.replace(/-/g,'_')
  console.log(`Slug: ${filteredSlug}`)
  const nestedDictionary = categoriesStyles[filteredSlug]; 
  

  return (
    <Wrapper backgroundcolor={nestedDictionary.backgroundcolor}>
      {postList ? (postsCanal.map((post, index) => (
        <div key={post.Title}>
          <Post 
            key={index} 
            post={post} 
            category={props.categoria}
            nestedDictionary={nestedDictionary}
            // isOpen={openStates[index]}
            // toggle={() => toggle(index)}            
          />
        </div>
      ))) : (
      <p>Loading...</p>
      )} 
      {/* <PostListStates postList={postList} posts={postsCanal} nestedDictionary={nestedDictionary}/> */}

    </Wrapper>
  );
};

export default PostList;

function convertDecimalCodes(inputString) {
  return inputString.replace(/&#(\d+);/g, function(match, decCode) {
    const char = String.fromCharCode(parseInt(decCode, 10));
    return char;
  });
}

function filterSlug (newSubCategory) {
  newSubCategory = newSubCategory.replace(/\s+/g, '-')
  newSubCategory = newSubCategory.replace(/\//g, '-', '-')
  newSubCategory = newSubCategory.replace('é','e')
  newSubCategory = newSubCategory.replace('á','a')
  newSubCategory = newSubCategory.replace('à','a')
  newSubCategory = newSubCategory.replace('ã','a')
  newSubCategory = newSubCategory.replace('â','a')
  newSubCategory = newSubCategory.replace('ó','o')
  newSubCategory = newSubCategory.replace('ô','o')
  newSubCategory = newSubCategory.replace('õ','o')
  newSubCategory = newSubCategory.replace('í','i')
  newSubCategory = newSubCategory.replace('ç','c')
  return newSubCategory
}

function filterAgendaContent(postList, newDate) {
  let agendaFiltrada = []
  for (const post of postList) {
    console.log('New Date at Filter:', newDate)

    let dataInicial =  invertDateFormat(post.Inicio.Data)
    let dataFinal = invertDateFormat(post.Fim.Data)
    
    console.log('data inicial', dataInicial)
    console.log('data final', dataFinal)
    let selectedStart = newDate.startDate.toISOString().split('T')[0]
    let selectedEnd = newDate.endDate.toISOString().split('T')[0]
    console.log('SELECTED START:', selectedStart)
    console.log('SELECTED END:', selectedEnd)

    let isPeriodOverlay = doDateRangesOverlap(dataInicial, dataFinal, selectedStart, selectedEnd)
    console.log('IS OVERLAY:', isPeriodOverlay)
    if (isPeriodOverlay) {
      agendaFiltrada.push(post)
    }
  }
  console.log('Agenda Filtrada:', agendaFiltrada)
  return agendaFiltrada
}

function doDateRangesOverlap(period1Start, period1End, period2Start, period2End) {
  console.log('period1Start', period1Start)
  console.log('period2End', period2End)
  console.log('period1End >= period2Start', period1End >= period2Start)
  console.log('period2Start', period2Start)
  console.log('period1End', period1End)
  console.log('period2End >= period1Start', period2End >= period1Start)
  return period1End >= period2Start && period2End >= period1Start;
}

function invertDateFormat(originalDate) {
  try{

    // Split the original date string into day, month, and year
    const [day, month, year] = originalDate.split('-');
    
    // Create a new date string in the "YYYY-MM-DD" format
    const invertedDate = `${year}-${month}-${day}`;
    
    return invertedDate;
  } catch(error) {
    console.log(error)
  }
  return '';
}


function isDateInRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate;
}

function removeHTML(inputString){
  return inputString.replace(/<[^>]*>/g, ''); 
}

function removeSpecialChar(input){
  return input.replace('\n', '').trim()
}

function cleanDate(inputDate) {
  // Remove HTML tags and newline characters
  const cleanString = inputDate.replace(/<[^>]*>/g, '').replace(/\n/g, '');
  console.log("clean string", cleanString)
  // Extract data and hora using regular expressions
  const dataMatch = cleanString.match(/data:\s*(\d{2}-\d{2}-\d{4})/);
  console.log("clean string", dataMatch)
  const horaMatch = cleanString.match(/hora:\s*(\d{2}-\d{2})/);
  console.log("clean string", horaMatch)

  const data = dataMatch ? dataMatch[1] : null;
  const hora = horaMatch ? horaMatch[1] : null;

  console.log('Data:', data);
  console.log('Hora:', hora);
  return {Data: data, Hora: hora}
}

function findKeyNamesInContent(content) {
  // Define a regular expression pattern to match key names enclosed in brackets
  const pattern = /\[([A-Z\s]+)\]/g;

  // Create an array to store the matched key names
  const keyNames = [];

  // Use the regular expression to find matches in the content
  let match;
  while ((match = pattern.exec(content)) !== null) {
    // The matched key name will be in match[1]
    const keyName = match[1];
    keyNames.push(keyName);
  }

  console.log("KEY NAMES");
  console.log(keyNames);
  // return keyNames;
}

function separateContentByKey(content) {
  // Define a regular expression pattern to match [KEY NAME]
  const pattern = /\[([A-Z\s]+)\]/g;

  // Use the pattern to split the content into an array
  const contentArray = content.split(pattern);

  // Filter out empty strings and trim whitespace from each element
  const filteredContentArray = contentArray
    .filter(text => text.trim() !== '')
    .map(text => text.trim());


  console.log(filteredContentArray);
  // return filteredContentArray;
}

function createDictionaryFromContent(content) {
  // Define a regular expression pattern to match [KEY NAME]
  // const pattern = /\[([A-Z\s]+)\]([\s\S]*?)(?=\[|$)/g;
  const pattern = /\[([A-Za-z\s]+)\]([\s\S]*?)(?=\[|$)/g;

  // Initialize an empty dictionary object
  const dictionary = {};

  // Use the pattern to extract key-value pairs
  let match;
  while ((match = pattern.exec(content)) !== null) {
    // console.log(match)
    const key = match[1].trim();
    const value = match[2].trim();
    dictionary[key] = value;
  }

  // console.log(dictionary);

  return dictionary;
}

function removeSubstrings(inputString, substringsToRemove) {
  // Escape special characters in substrings and join them with "|" for the regex pattern
  const regexPattern = new RegExp(substringsToRemove.map(sub => sub.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'), 'g');

  // Use replace with the regex pattern to remove substrings
  const resultString = inputString.replace(regexPattern, '');

  return resultString;
}

function cleanHTML(data) {
  const substringsToRemove = ['<p>', '</p>',  '<strong>', '</strong>', 'facilidades', '<br>'];
  const cleanededString = removeSubstrings(data, substringsToRemove);
  return cleanededString
}

function getServices(data) {
  const services = [];

  const substringsToRemove = ['<p>', '</p>',  '<strong>', '</strong>', 'facilidades'];
  try {
    const lines = data.trim().split('<br>');
    for (const line of lines) {
      // clean line
      const cleanededString = removeSubstrings(line, substringsToRemove);
      const noHTTP = cleanededString.replace("https://","https//")
      const noHTTPS = noHTTP.replace("http://","http//")
      // Split each line into key and content
      const [key, content] = noHTTPS.split(':').map((item) => item.trim())

       if (content != '' && key != '') {  
        let finalLink = ''
        finalLink = content.replace('http//','http://')
        finalLink = finalLink.replace('https//','https://')

        const modifiedString3 = key.replace('á','a');
        const modifiedString2 = modifiedString3.replace('ã','a');
        const finalKey = modifiedString2.replace('ç','c');
        const isTel = (key == 'tel') 
        if (!services[key]) {
          services.push({
            'text': key.trim(),
            'imageUrl': iconesList[finalKey],
            'link': finalLink,
            'isTel': isTel
          }) // Create the inner dictionary if it doesn't exist
        }
      }
      
    }
  } catch (error) {
    console.error(error);
  }
  // console.log("Meus Serviços:", services)
  // console.log("Objects serviços:", Object.keys(services))
  return services;
}

function PostListStates({ postList, posts, nestedDictionary }) {
  const [openStates, setOpenStates] = useState(posts.map(() => false));

  const toggle = (index) => {
    const updatedStates = [...openStates];
    updatedStates[index] = !updatedStates[index];
    setOpenStates(updatedStates);
  };

  return (
    <Wrapper backgroundcolor={nestedDictionary.backgroundcolor}>
      {postList ? (posts.map((post, index) => (
        <div>
          <Post 
            key={index} 
            post={post} 
            nestedDictionary={nestedDictionary}
            isOpen={openStates[index]}
            toggle={() => toggle(index)}            
          />
        </div>
      ))) : (
      <p>Loading...</p>
      )} 

    </Wrapper>
  );
}

function Post({ post, nestedDictionary, category }) {
  const [isOpen, setIsOpen] = useState(false); // Separate state for each post

  const toggle = () => {
    setIsOpen(!isOpen); // Toggle the state when the button is clicked
  };

  return (
    <StyledPostWrapper key={post.Title}>
    <PostImage>
      {post.FeaturedMedia && (
        <StyledImage
        src={post.FeaturedMedia[0].source_url}
        alt={convertDecimalCodes(post.Title)}
        />
      )}
      <PostTitle backgroundcolor={nestedDictionary.backgroundcolorTitle}>{convertDecimalCodes(post.Title)}</PostTitle>
    </PostImage>
    {(category == "agenda" || category == 'destaques-da-agenda' || category == 'feiras-e-eventos')? <PostDate date={post.Inicio.Data} dateE={post.Fim.Data} original={false}/> : ''}
    {(category == "agenda" || category == 'destaques-da-agenda' || category == 'feiras-e-eventos')? <LocalEvento> {post.LocalEvento }</LocalEvento>: ''}
    <LineContentPreview dangerouslySetInnerHTML={{ __html: post.Dictionary.LINHAS }} />
    {!isOpen ? 
      <PostContentPreview dangerouslySetInnerHTML={{ __html: post.Dictionary.CONTENT.substring(0,180) + '...' }}/> :
      <PostContentPreview dangerouslySetInnerHTML={{ __html: post.Dictionary.CONTENT}}/>}
    {isOpen && <StyledTitle>Serviços</StyledTitle>}
    {isOpen && <PostContentPreview dangerouslySetInnerHTML={{ __html: "<br>Endereço: "+post.Dictionary.LOCAL }} />}
    {/* {isOpen && <PostContentPreview dangerouslySetInnerHTML={{ __html: "<br>Horário: "+post.Dictionary.INICIO }} />} */}
    {isOpen && <ContactList items={post.Contato}/>}
    {/* {isOpen && <ServicesList icons={icons}/>} */}
    {isOpen && <ComoChegar address={post.Dictionary.LOCAL}/>}
    {/* <button onClick={toggle}>Toggle</button> */}
    <Container>
      <StyledLink onClick={toggle}>{isOpen ? 'ver menos': 'ver mais'}</StyledLink>
    </Container>
  </StyledPostWrapper>


  );
}

const mitems = [
  { text: 'Home', imageUrl: iconesList['tel'], link: "https://google.com" },
  { text: 'Profile', imageUrl: iconesList['facebook'], link: 'https://google.com'},
  { text: 'Instagram', imageUrl: iconesList['instagram'], link: 'https://google.com'},
  { text: 'site', imageUrl: iconesList['site'], link: 'https://google.com'},
  { text: 'cardápio', imageUrl: iconesList['cardapio'], link: 'https://google.com'},
];

const icons = [
  { imageUrl: iconesList['ciclovia'], text: 'Ciclovia' },
  { imageUrl: iconesList['estacionamento'], text: 'Profile' },
  { imageUrl: iconesList['pet'], text: 'Messages' },
];

// const keysFound = findKeyNamesInContent(postContent);
// console.log(keysFound);

// Render Antigo dos Posts para referências

// {postList ? (postList.map((post) => (
//   <div>
//     {/* {setContentDictionary(createDictionaryFromContent(post.content.rendered))} */}
//     {/* {console.log(contentDictionary['LINHAS'])} */}
//     <PostImage>
//       {post._embedded?.['wp:featuredmedia'] && (
//         <StyledImage
//         src={post._embedded['wp:featuredmedia'][0].source_url}
//         alt={convertDecimalCodes(post.title.rendered)}
//         />
//       )}
//       <PostTitle backgroundcolor={nestedDictionary.backgroundcolorTitle}>{convertDecimalCodes(post.title.rendered)}</PostTitle>
//     </PostImage>

//     <PostContentPreview dangerouslySetInnerHTML={{ __html: post.content.rendered.substring(0,350) + '...' }} />
//     {/* <PostContentPreview dangerouslySetInnerHTML={{ __html: contentDictionary["LINHAS"] }} /> */}
//     <StyledLink href="google.com" target="_blank">ver mais</StyledLink>
//   </div>
// ))) : (
// <p>Loading...</p>
// )} 