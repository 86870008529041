import React from 'react';
import { FooterWrapper, UpperDiv, LowerDiv, LogoRadar, Container } from './styles.js';
import {logoAddress }from "../constants.js"

const Footer = () => {
    return (
      <FooterWrapper>
        <Container>
            <LogoRadar src={logoAddress} />
        </Container>
        <UpperDiv>
            <p>termos de uso e política de privacidade <br/> relate um problema <br/> contato: 11 98388-8866</p>
        </UpperDiv>
        <LowerDiv>
            <p>2022 - &copy; Todos os direitos reservados ao radar360</p>
        </LowerDiv>
      </FooterWrapper>
    );
  }

export default Footer;