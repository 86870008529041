import React from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Header,
  LogoRadar,
  Patrocinio,
  PatrocinioText,
  PatrocinioLogoRadar,
  Brace,
} from "./styles"

import RoutedToggleMenu from '../RoutedToggleMenu/RoutedToggleMenu';
import Capa from '../Capa';
import ChamadaMobile from '../ChamadaMobile/ChamadaMobile'
import MenuCanais from '../MenuCanais/MenuCanais';
import Footer from '../Footer/Footer';
import { siteAddress } from '../constants';
import Destaques from '../Destaques/Destaques';
import Apoio from '../Apoio/apoio';
import ApoioRadar from '../ApoioRadar/apoioRadar';
// import { Patrocinio, PatrocinioLogoRadar } from './styles';
let logoAddress = 'https://' +siteAddress+ '/wp-content/uploads/2023/09/Logo-radar-branco-fundo-Transparente.png'
let patrocinioImg = 'https://' +siteAddress+ '/wp-content/uploads/2023/09/Icone-patrocinio-2.webp'

function HomeMobile() {

  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };



  return (
    <Wrapper>
      <Header>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")}/>
        <RoutedToggleMenu />
      </Header>
      <Brace>
        <LogoRadar src={logoAddress}/>
        <RoutedToggleMenu />
      </Brace>
      {/* <Brace /> */}
      <Capa />
      {/* <Patrocinio>
        //  {/* <PatrocinioText>Patrocinio</PatrocinioText>
      </Patrocinio> */}
      <PatrocinioLogoRadar src={patrocinioImg} />
      <Destaques categoria='destaques-da-agenda'/>
      <ChamadaMobile categoria='chamada-arte-e-cultura'/>
      <ChamadaMobile categoria='chamada-agenda'/>
      <ChamadaMobile categoria='chamada-feiras-e-eventos'/>
      <ChamadaMobile categoria='chamada-comer-e-beber'/>
      <ChamadaMobile categoria='chamada-radar-secreto'/>
      <ChamadaMobile categoria='chamada-metro'/>
      {/* <CanalMobile categoria='chamada-radar-secreto'/> */}
      {/* <Apoio /> */}
      <ApoioRadar />
      <MenuCanais />
      <Footer/>

      
    </Wrapper>
  );
};

export default HomeMobile;