import React from 'react';
// import {} from './styles.js';
// import {logoAddress }from "../constants.js"
import "./styles.css"
import { StyledDateSquare } from './styles';

const DateSquare = (props) => {
    // const dateString = props.day+"-"+props.month+"-"+props.year
    const date = new Date(props.dateString);
    const dayOfWeek = date.getDay();
    const day = date.getDate();
    const monthOfYear = date.getMonth();
    const weekdays = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const months = ['JAN','FEV','MAR','ABR','MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']
    const weekday = weekdays[dayOfWeek]
    const month = months[monthOfYear]

    return (
      <div className="date-wrapper">
        <div className="weekday">{props.suff+" "+weekday}</div>
        <StyledDateSquare backgroundcolor={props.backgroundcolor}>
          <span className="day">{day}</span>
          <span className="month">{month}</span>
        </StyledDateSquare>
      </div>
    );
  }

export default DateSquare;