import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';

import {
  Patrocinio,
  PatrocinioAgenda,
  Container,
  StyledTitle,
  Separator,
  TwoColumns,
  PatrocinioLogoRadar,
  PatrocinioLogoSecreto,
} from "./styles"

import { Wrapper, Brace, Header, LogoRadar } from '../Home/styles';

import RoutedToggleMenu from '../RoutedToggleMenu/RoutedToggleMenu';
import PostList from '../PostList'
import SelectCategory from '../SelectCategory/SelectCategory';
import Footer from '../Footer/Footer';
import { categoriesStyles, siteAddress, logoAddress } from '../constants';
import Description from '../DescriptionMobile/Description';
import AgendaSelector from '../AgendaSelector/AgendaSelector';
import { patrocinioBanner } from '../../Mobile/constants';

function PaginaCategoriaDesktop({categoria}) {
  const [subCategory, setSubCategory] = useState('categoria');
  const [agendaDate, setAgendaDate] = useState(getDateRanges());
  console.log(`Categoria: ${categoria}`)
  const filteredSlug = categoria.replace(/-/g,'_')
  let filterLists = [categoriesStyles[filteredSlug].tags]
  let filterListsFeirasEventos = [categoriesStyles['feiras_e_eventos'].tags]


  // Handle the internal navigation
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  useEffect(() => {
      console.log("PAGINA")
      setSubCategory('categoria'); // Reset state1   
  }, []);

  const handleSelector = (newSubCategory) => {
    newSubCategory = newSubCategory.replace(/\s+/g, '-')
    newSubCategory = newSubCategory.replace(/\//g, '-', '-')
    newSubCategory = newSubCategory.replace('é','e')
    newSubCategory = newSubCategory.replace('ó','o')
    newSubCategory = newSubCategory.replace('ç','c')
    setSubCategory(newSubCategory)

    console.log("New subCategory", newSubCategory)
  }

  const handleAgendaSelector = (newDate, selectedDateRange) => {
    console.log("New Date Handler:", newDate)
    console.log("Start Date:", selectedDateRange.startDate.toISOString().split('T')[0])
    console.log("New Date Handler:", selectedDateRange.endDate.toISOString().split('T')[0])
    setAgendaDate(selectedDateRange)
  }

  


  return (
    <Wrapper>
      <Header>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")} />
        <RoutedToggleMenu />      
      </Header>
      <Brace>
        <LogoRadar src={logoAddress} onClick={() => handleNavigation("/")} />
        <RoutedToggleMenu />
      </Brace>
      <Container>
        <StyledTitle>{categoria.replace(/-/g,' ')}</StyledTitle>
      </Container>
      <Separator />

      <TwoColumns>
      { categoria !== 'agenda' && categoria !== 'radar-secreto' ?
      <SelectCategory categoria={categoria} filterLists={filterLists} callBack={handleSelector}/> : '' } 
      { categoria !== 'agenda' && categoria !== 'radar-secreto' ?
      <Patrocinio><PatrocinioLogoRadar src={patrocinioBanner}/></Patrocinio> : '' }
      { categoria === 'agenda' ? <AgendaSelector callback={handleAgendaSelector}/> : ''} 
      { categoria === 'agenda' ? <PatrocinioAgenda><PatrocinioLogoRadar src={patrocinioBanner}/> </PatrocinioAgenda> : ''} 
      { categoria === 'radar-secreto' ? <PatrocinioAgenda><PatrocinioLogoSecreto src={patrocinioBanner}/> </PatrocinioAgenda> : ''} 
      
      
      </TwoColumns>
      <Description categoria={categoria}/>
      {/* {categoria == 'agenda' ? <DateRangeComponent/> : ''} */}
      <PostList categoria={categoria} subCategoria={subCategory} agendaDate={agendaDate}/>
      {/* <MenuCanais /> */}
      <Footer/>
    </Wrapper>
  );
};

export default PaginaCategoriaDesktop;

function getDateRanges() {
  const today = new Date();
  const currentDay = today.getDay();
  const startDate = new Date(today);
  const endDate = new Date(today);
  const next365DaysEndDate = new Date(today); 
  // Calculate the start date (last Monday)
  const start = ((currentDay - 1) < 0) ? 6 : (currentDay - 1);
  console.log('START', currentDay)
  console.log('START', today)
  startDate.setDate(today.getDate() - start);
  console.log('START', startDate)

  // Calculate the end date (next Sunday)
  const end = (currentDay === 0) ? 0 : (7 - currentDay);
  endDate.setDate(today.getDate() + end);
  // Calculate the start date for the next 365 days
  next365DaysEndDate.setDate(today.getDate() + 365);


  return (
    {
      label: 'toda agenda',
      startDate,
      next365DaysEndDate, //change end Date
    }
    )
}

