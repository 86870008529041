import React from 'react';
import styled from 'styled-components';

// Define the styled component
export const StyledButton = styled.button`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Header = styled.header`
  background-color: #f44336;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Define the styled component
export const Footer = styled.footer`
background-color: #222;
color: #fff;
padding: 12px;
text-align: center;
position: fixed;
left: 0;
bottom: 0;
width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color:${props => props.backgroundcolor};
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 0px 0px;
`;

export const PostTitle = styled.h1`
  display: flex;
  padding-left:15px;
  padding-right:15px;
`;

export const PostContentPreview = styled.div`
  font-size: 25px;
  background-color:${props => props.backgroundcolor};
  margin-top: 0px;
  padding: 3px 12px 5px 12px;
  text-align: center;
  color: #fff;
`;

export const StyledImage = styled.img`
max-width: 100%;
max-height: 100%;
margin: 0px 0px 0px 0px;
`;

export const StyledLink = styled.a`
  color: gray;
  text-decoration: underline;
  cursor: pointer;
  padding: 12px;
  margin-botton: 100px;
  margin-top: 100px;
  
  &:hover {
    opacity: 0.6;
  }
`;