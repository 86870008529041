import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ToggleMenu.css';

const RoutedToggleMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    setIsOpen(false);
    navigate(path);
  };

  return (
    <div className="toggle-menu-desktop">
      <div
        className={`menu-icon ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
          <li onClick={() => handleNavigation('/')}>home</li>
          <li onClick={() => handleNavigation('/agenda')}>agenda</li>
          <li onClick={() => handleNavigation('/comer-e-beber')}>comer e beber</li>
          <li onClick={() => handleNavigation('/arte-e-cultura')}>arte e cultura</li>
          <li onClick={() => handleNavigation('/lugares-para-visitar')}>lugares para visitar</li>
          <li onClick={() => handleNavigation('/radar-secreto')}>radar secreto</li>
          <li onClick={() => handleNavigation('/feiras-e-eventos')}>feiras e eventos</li>
          <li onClick={() => handleNavigation('/compras')}>compras</li>
          <li onClick={() => handleNavigation('/roteiros')}>roteiros</li>
          {/* <li onClick={() => handleNavigation('/radar-da-estacao')}>radar da estação</li> */}
          <li onClick={() => handleNavigation('/radar-da-linha')}>radar da linha</li>
      </ul>
    </div>
  );
};

export default RoutedToggleMenu;
