import React from 'react';
import { FooterWrapper, LowerDiv, LogoRadar,Column, TwoColumnFlex, CenteredTwoColumnFlex } from './styles.js';
import {logoAddress }from "../constants.js"

const Footer = () => {
    return (
      <FooterWrapper>           
        <LogoRadar src={logoAddress} />
        <Column>
          Termos de uso e política de privacidade | relate problema | Contato: Fone - whatsapp: 11 98388-8866
        </Column>
        <LowerDiv>
            <p>2022 - &copy; Todos os direitos reservados ao radar360</p>
        </LowerDiv>
      </FooterWrapper>
    );
  }

export default Footer;